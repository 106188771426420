/* eslint-disable arrow-body-style */
/* eslint-disable no-inner-declarations */
/* eslint-disable react/prop-types */
/* eslint-disable no-new */
/* eslint-disable no-undef */
/* eslint-disable react/self-closing-comp */
import { Box } from '@mui/material';
import { useEffect } from 'react';

export default function Language(props) {
    const { id } = props;

    useEffect(() => {
        let isInitialized = false;

        const loadScript = () => {
            return new Promise((resolve, reject) => {
                if (document.getElementById('google-translate-script')) {
                    resolve();
                    return;
                }

                const script = document.createElement('script');
                script.src = "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
                script.async = true;
                script.id = 'google-translate-script';
                script.onload = () => resolve();
                script.onerror = () => reject(new Error("Script load error"));
                document.body.appendChild(script);
            });
        };

        const googleTranslateElementInit = () => {
            if (!isInitialized && window.google && window.google.translate) {
                isInitialized = true;
                new window.google.translate.TranslateElement(
                    {
                        pageLanguage: "en",
                        autoDisplay: "true",
                        includedLanguages: "en,fr,es,ar,gd",
                        layout: window.google.translate.TranslateElement.InlineLayout.HORIZONTAL,
                    },
                    id
                );
            }
        };

        if (id) {
            loadScript()
                .then(() => {
                    setTimeout(googleTranslateElementInit, 100);
                })
                .catch(error => {
                    console.error(error);
                });
        }

        return () => {
            const script = document.getElementById('google-translate-script');
            if (script) {
                document.body.removeChild(script);
            }
        };
    }, [id]);

    return (
        <Box>
            {id && <div id={id}></div>}
        </Box>
    );
}
