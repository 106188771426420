/* eslint-disable prefer-const */
/* eslint-disable no-new */
/* eslint-disable no-undef */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/button-has-type */
import { useContext, useState } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// @mui
import { googleLogout } from '@react-oauth/google';
import { Box, Stack, MenuItem, alpha, Avatar, Typography, Divider, useMediaQuery } from '@mui/material';
import BorderClearIcon from '@mui/icons-material/BorderClear';
import LogoutIcon from '@mui/icons-material/Logout';
// components
// import Image from '../../components/Image';
// import whiteLogo from '../../assets/web_book.png';
import { useSnackbar } from 'notistack';
//
import MainHeader from './MainHeader';
import MainFooter from './MainFooter';
import '../../sections/home/home.css';
import Image from '../../components/Image';
import whiteLogo from '../../assets/web_book.png';
import MenuPopover from '../../components/MenuPopover';
import useIsMountedRef from '../../hooks/useIsMountedRef';
import { IconButtonAnimate } from '../../components/animate';
import useAuth from '../../hooks/useAuth';
import Language from './Language';
import { NavConfigContext } from '../../contexts/ToogleContext';

// ----------------------------------------------------------------------

export default function MainLayout() {
  const [open, setOpen] = useState(null);
  const navigate = useNavigate();
  const { navConfigToogle, handleToggle } = useContext(NavConfigContext);
  const token = localStorage.getItem('accessToken');
  const { user, logout } = useAuth();
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();
  const currentUser = JSON.parse(localStorage.getItem('currentuser'));

  const handleClose = () => {
    setOpen(null);
  };
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleLogout = async () => {
    try {
      await logout();
      localStorage.removeItem('currentuser');
      localStorage.removeItem('accessToken');
      localStorage.removeItem('navconfigtoogle');
      setNavConfigToogle(true);
      localStorage.setItem('navconfigtoogle', true);
      navigate('/', { replace: true });
      googleLogout();

      if (isMountedRef.current) {
        handleClose();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  const isMediumScreen = useMediaQuery(theme => theme.breakpoints.up('md'));

  return (
    <>
      <Stack sx={{ minHeight: 1 }}>
        <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
          {token ? (
            <Box
              sx={{
                display: 'flex',
                position: 'fixed',
                justifyContent: 'space-between',
                gap: 3,
                backgroundColor: '#1F1F1F',
                alignItems: 'center',
                height: '60px',
                paddingRight: 6,
                top: 0,
                width: '100%',
                zIndex: 10000000,
              }}
            >
              <Box>
                <Image visibleByDefault disabledEffect alt="login" src={whiteLogo} />
              </Box>
              <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                <Box>
                  <Language id={isMediumScreen ? "google_translate_element_web" : null} />
                </Box>
                <button className="seller_btn">
                  <Link
                    style={{ textDecoration: 'none', fontSize: '11px', fontWeight: 600 }}
                    className="popin"
                    to={token ? (navConfigToogle ? `/tickets` : '/') : '/user/login'}
                    onClick={() => handleToggle(token, navigate)}
                  >
                    {' '}
                    {navConfigToogle ? 'Sell your tickets' : 'Buy Now'}
                  </Link>
                </button>

                <Box>
                  <IconButtonAnimate
                    onClick={handleOpen}
                    sx={{
                      p: 0,
                      ...(open && {
                        '&:before': {
                          zIndex: 1,
                          content: "''",
                          width: '100%',
                          height: '100%',
                          borderRadius: '50%',
                          position: 'absolute',
                          bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
                        },
                      }),
                    }}
                  >
                    {
                      console.log({
                        google: currentUser?.picture || user?.picture
                      }, {
                        user: `http://webook-ticket.devssh.xyz${currentUser?.image || `http://webook-ticket.devssh.xyz${user?.image}`}`
                      })
                    }
                    {token ? (
                      <>
                        <Avatar
                          alt="Profile Image"
                          src={currentUser?.picture || user?.picture || `http://webook-ticket.devssh.xyz${currentUser?.image || `http://webook-ticket.devssh.xyz${user?.image}`}`}
                          sx={{ width: 40, height: 40 }}
                        />
                      </>
                    ) : (
                      <Avatar sx={{ width: 40, height: 40 }}>MR</Avatar>
                    )}
                  </IconButtonAnimate>

                  <MenuPopover
                    open={Boolean(open)}
                    anchorEl={open}
                    onClose={handleClose}
                    sx={{
                      p: 0,
                      mt: 1.5,
                      ml: 0.75,
                      width: '230px',
                      '& .MuiMenuItem-root': {
                        typography: 'body2',
                        borderRadius: 0.75,
                      },
                    }}
                  >

                    <Stack sx={{ p: 2, width: 220 }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                        <Avatar
                          alt="Profile Image"
                          src={currentUser?.picture || user?.picture || `http://webook-ticket.devssh.xyz${currentUser?.image || `http://webook-ticket.devssh.xyz${user?.image}`}`}
                          sx={{ width: 40, height: 40 }}
                        />
                        <Box sx={{ ml: 2 }}>
                          <Typography variant="subtitle1" noWrap>
                            {currentUser?.first_name || user?.first_name ? currentUser?.first_name || user?.first_name : currentUser?.name || user?.name}
                          </Typography>
                          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                            <Link to={'/'}>View Profile</Link>
                          </Typography>
                        </Box>
                      </Box>

                      <Divider sx={{ borderStyle: 'dashed', mt: 2 }} />

                      <MenuItem
                        onClick={() => {
                          navigate('/order');
                          handleToggle(token, navigate)
                        }}
                      >
                        <Box component="span" sx={{ display: 'flex', alignItems: 'center' }}>
                          <Box component="span" sx={{ mr: 1, display: 'inline-flex', alignItems: 'center' }}>
                            <BorderClearIcon />
                          </Box>
                          My Orders
                        </Box>
                      </MenuItem>

                      <Divider sx={{ borderStyle: 'dashed' }} />

                      <MenuItem onClick={handleLogout} sx={{ bgcolor: alpha('#FFC0CB', 0.3) }}>
                        <Box component="span" sx={{ display: 'flex', alignItems: 'center' }}>
                          <Box component="span" sx={{ mr: 1, display: 'inline-flex', alignItems: 'center' }}>
                            <LogoutIcon />
                          </Box>
                          Logout
                        </Box>
                      </MenuItem>
                    </Stack>
                  </MenuPopover>
                </Box>
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                position: 'fixed',
                justifyContent: 'space-between',
                gap: 3,
                backgroundColor: '#1F1F1F',
                alignItems: 'center',
                height: '60px',
                paddingRight: 6,
                top: 0,
                width: '100%',
                zIndex: 10000000,
              }}
            >
              <Box>
                <Image visibleByDefault disabledEffect alt="login" src={whiteLogo} sx={{ width: 200 }} />
              </Box>
              <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                <Box>
                  <Language id={isMediumScreen ? "google_translate_element_web" : null} />
                </Box>
                <Box sx={{ width: '1px', height: '28px', backgroundColor: 'white' }} className="mx-2" />
                <button
                  className="login_btn"
                >
                  <Link
                    className="popin"
                    style={{ textDecoration: 'none', color: 'inherit', fontWeight: 600, fontSize: '11px' }}
                    to="/user/login"
                  >
                    Log in
                  </Link>
                </button>

                <Box sx={{ width: '1px', height: '28px', backgroundColor: 'white' }} className="mx-2" />

                <button className="seller_btn">
                  <Link
                    style={{ textDecoration: 'none', fontSize: '11px', fontWeight: 600 }}
                    className="popin"
                    to={token ? `/` : '/user/login'}
                  >
                    {navConfigToogle ? 'Sell your tickets' : 'Buy Now'}
                  </Link>
                </button>
              </Box>
            </Box>
          )}
        </Box>

        <MainHeader navConfigToogle={navConfigToogle} />
        <Outlet />
        <Box sx={{ flexGrow: 1 }} />

        <Box
          sx={{
            textAlign: 'center',
            position: 'relative',
            backgroundImage: 'url(/images/footer.png)',
          }}
        >
          <MainFooter />
        </Box>
      </Stack >
    </>
  );
}
