/* eslint-disable no-unused-vars */
/* eslint-disable react/button-has-type */
import { styled } from '@mui/material/styles';
import { Grid, Link, Divider, Container, Typography, Stack, Box } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import Image from '../../components/Image';
import whiteLogo from '../../assets/web_book.png';
import SocialsButton from '../../components/SocialsButton';

// ----------------------------------------------------------------------

const LINKS = [
  {
    headline: 'Our Company',
    children: [
      { name: 'Get Started', href: '#' },
      { name: 'About Us', href: '#' },
      { name: 'Contact Us', href: '#' },
      { name: 'Blog', href: '#' },
    ],
  },
  {
    headline: 'Our Office',
    children: [
      { name: '515 S Flower Street 18th Fl', href: '#' },
      { name: 'Los Angeles, CA', href: '#' },
      { name: '90071', href: '#' },
    ],
  },
  {
    headline: 'Leagues Info',
    children: [
      { name: 'NBA', href: '#' },
      { name: 'NHL', href: '#' },
      { name: 'MLB', href: '#' },
      { name: 'NFL', href: '#' },
      { name: 'MLS', href: '#' },
    ],
  },
];

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundImage: 'url(/images/footer.png)',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  overflow: 'hidden',
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'end',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    zIndex: 1,
  },
}));

// ----------------------------------------------------------------------

export default function MainFooter() {
  return (
    <RootStyle className="py-5">
      <Divider />
      <Container>
        <Grid container justifyContent="center" sx={{ position: 'relative', zIndex: 2 }}>
          <Grid item xs={12} md={3}>
            <Box sx={{ width: 200 }}>
              <Image visibleByDefault disabledEffect alt="login" src={whiteLogo} />
            </Box>
            <Typography className="text-white" variant="body2" sx={{ ml: 2, padding: '0.5rem', borderRadius: '4px' }}>
              (123) 456-7890
            </Typography>
          </Grid>

          <Grid item xs={12} md={8}>
            <Stack
              spacing={5}
              direction="row"
              flexWrap="wrap"
              justifyContent="space-between"
              className="text-white text-left"
            >
              {LINKS.map((list) => (
                <Stack key={list.headline} spacing={2} marginLeft="0px !important">
                  <Typography
                    className="text-orange"
                    style={{
                      fontSize: 20,
                      fontWeight: 'bold',
                    }}
                  >
                    {list.headline}
                  </Typography>
                  {list.children.map((link) => (
                    <Link
                      to={link.href}
                      key={link.name}
                      color="inherit"
                      variant="body2"
                      className="footer-link mt-0"
                      component={RouterLink}
                      sx={{
                        display: 'block',
                        padding: '0.5rem',
                        borderRadius: '4px',
                      }}
                    >
                      {link.name}
                    </Link>
                  ))}
                </Stack>
              ))}
            </Stack>
          </Grid>
        </Grid>

        <Grid container spacing={2} sx={{ position: 'relative', zIndex: 2 }} justifyContent="center">
          <Grid item xs={12} md={4} className="p-0">
            <Stack direction="row" sx={{ mb: 2 }} className="text-white ms-2">
              <SocialsButton sx={{ mx: 0.5 }} />
            </Stack>
            <Typography
              component="p"
              variant="body2"
              className="text-white"
              sx={{
                fontSize: 13,
                textAlign: 'center',
                padding: '0.5rem',
                borderRadius: '4px',
              }}
            >
              ©2024 HNH Soft Tech Solutions - All rights reserved.
            </Typography>
          </Grid>

          <Grid item xs={12} md={7}>
            <div
              className="d-flex align-items-center flex-wrap gap-2 justify-content-end"
              style={{ position: 'relative', zIndex: 2 }}
            >
              <Typography
                component="p"
                variant="body2"
                className="text-white"
                sx={{
                  fontSize: 18,
                  textAlign: 'center',
                  padding: '0.5rem',
                  borderRadius: '4px',
                  fontWeight: 'bold',
                }}
              >
                Subscribe to our newsletter
              </Typography>
              <div className="d-flex align-items-center footer">
                <input type="text" placeholder="Type your email" />
                <button>Subscribe</button>
              </div>
            </div>
            <Typography
              component="p"
              variant="body2"
              className="text-white me-2 mt-4"
              sx={{
                fontSize: 13,
                textAlign: 'end',
                padding: '0.5rem',
                borderRadius: '4px',
              }}
            >
              Privacy Policy Terms & Conditions
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </RootStyle>
  );
}
