import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
import DashboardLayout from '../layouts/dashboard';
import { PATH_AFTER_LOGIN } from '../config';
import LoadingScreen from '../components/LoadingScreen';
import MainLayout from '../layouts/main';

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {

  return useRoutes([
    { path: '/', element: <Home /> },
    { path: '/admin/login', element: <Login /> },
    { path: '/seller/login', element: <LoginSeller /> },
    { path: 'auth/register', element: <Register /> },
    { path: '/user/register', element: <RegisterUser /> },
    { path: '/user/login', element: <LoginUser /> },
    // Admin Dashboard Routes Start
    {
      path: 'dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'home', element: <GeneralAnalytics /> },
        { path: 'user', element: <User /> },
        { path: 'adduser', element: <AddUser /> },
        { path: 'edituser/:id', element: <EditUser /> },
        { path: 'subadmin', element: <SubAdmin /> },
        { path: 'addsubadmin', element: <AddSubAdmin /> },
        { path: 'editsubadmin/:id', element: <EditSubAdmin /> },
        { path: 'subadminpermission', element: <SubAdminPermission /> },
        { path: 'addsubadminpermission', element: <AddSubAdminPermission /> },
        { path: 'editsubadminpermission/:id', element: <EditSubAdminPermission /> },
        { path: 'edituser', element: <EditSeller /> },
        { path: 'category', element: <Category /> },
        { path: 'addcategory', element: <AddCategory /> },
        { path: 'editcategory/:id', element: <EditCategory /> },
        { path: 'editadmin', element: <EditAdmin /> },
        { path: 'event', element: <Event /> },
        { path: 'addevent', element: <AddEvent /> },
        { path: 'editevent/:id', element: <EditEvent /> },
        { path: 'league', element: <League /> },
        { path: 'buyerandseller', element: <BuyerAndSeller /> },
        { path: 'addleague', element: <AddLeague /> },
        { path: 'editleague/:id', element: <EditLeague /> },
        { path: 'team', element: <Team /> },
        { path: 'addteam', element: <AddTeam /> },
        { path: 'editteam/:id', element: <EditTeam /> },
        { path: 'leagueteam', element: <LeagueTeam /> },
        { path: 'addleagueteam', element: <AddLeagueTeam /> },
        { path: 'editleagueteam/:id', element: <EditLeagueTeam /> },
        { path: 'matches', element: <Matches /> },
        { path: 'addmatches', element: <AddMatches /> },
        { path: 'addmatcheresult/:id', element: <AddMatcheResult /> },
        { path: 'editmatches/:id', element: <EditMatches /> },
        { path: 'matcheticket', element: <MatcheTicket /> },
        { path: 'addmatcheticket', element: <AddMatcheTicket /> },
        { path: 'editmatcheticket/:id', element: <EditMatcheTicket /> },
        {
          path: 'e-commerce',
          children: [{ element: <Navigate to="/dashboard/e-commerce/shop" replace />, index: true }],
        },
      ],
    },
    // Admin Dashboard Routes End

    // User Routes Start
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { element: <HomePage />, index: true },
        { path: 'buying/:id', element: <Buying /> },
        { path: 'ticketdetail/:id', element: <TicketDetailPage /> },
        { path: 'contact', element: <Contact /> },
        { path: 'about', element: <About /> },
        { path: 'teamss', element: <Teamss /> },
        { path: 'allmatches/:id', element: <AllMatches /> },
        { path: 'checkout/:id', element: <Checkout /> },
        { path: 'eventsleagues/:id', element: <EventsLeagues /> },
        { path: 'eventmatch/:id', element: <EventMatch /> },
        { path: 'allleagues', element: <AllLeagues /> },
        { path: 'allmatches', element: <AllMatch /> },
        { path: 'allevents', element: <AllEvents /> },
        { path: 'profile', element: <UserProfile /> },
        { path: 'soldmatch', element: <SoldMatch /> },
        { path: 'soldticket/:id', element: <SoldTickets /> },
        { path: 'order', element: <Order /> },
        { path: 'bankinfo', element: <BankInfo /> },
        { path: 'edittickets/:id', element: <EditTicket /> },
        { path: 'tickets', element: <Tickets /> },
        { path: 'addtickets', element: <AddTickets /> },
      ],
    },
    // User Routes End
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const LoginSeller = Loadable(lazy(() => import('../pages/auth/LoginSeller')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const RegisterUser = Loadable(lazy(() => import('../pages/auth/UserRegister')));
const LoginUser = Loadable(lazy(() => import('../pages/auth/LoginUser')));

const Home = Loadable(lazy(() => import('../pages/dashboard/Home/Home')));
const GeneralAnalytics = Loadable(lazy(() => import('../pages/dashboard/GeneralAnalytics')));

const EditAdmin = Loadable(lazy(() => import('../pages/dashboard/EditAdmin/EditAdmin')));
const UserProfile = Loadable(lazy(() => import('../pages/UserProfile/UserProfile')));

const User = Loadable(lazy(() => import('../pages/dashboard/User/User')));
const AddUser = Loadable(lazy(() => import('../pages/dashboard/User/AddUser')));
const EditUser = Loadable(lazy(() => import('../pages/dashboard/User/EditUser')));
const SubAdminPermission = Loadable(lazy(() => import('../pages/dashboard/SubAdmin/SubAdminPermission')));
const AddSubAdminPermission = Loadable(lazy(() => import('../pages/dashboard/SubAdmin/AddSubAdminPermission')));
const EditSubAdminPermission = Loadable(lazy(() => import('../pages/dashboard/SubAdmin/EditSubAdminPermission')));
const SubAdmin = Loadable(lazy(() => import('../pages/dashboard/SubAdmin/SubAdmin')));
const AddSubAdmin = Loadable(lazy(() => import('../pages/dashboard/SubAdmin/AddSubAdmin')));
const EditSubAdmin = Loadable(lazy(() => import('../pages/dashboard/SubAdmin/EditSubAdmin')));
const EditSeller = Loadable(lazy(() => import('../pages/dashboard/EditSeller/EditSeller')));
const Event = Loadable(lazy(() => import('../pages/dashboard/Event/Event')));
const AddEvent = Loadable(lazy(() => import('../pages/dashboard/Event/AddEvent')));
const EditEvent = Loadable(lazy(() => import('../pages/dashboard/Event/EditEvent')));
const League = Loadable(lazy(() => import('../pages/dashboard/League/League')));
const AddLeague = Loadable(lazy(() => import('../pages/dashboard/League/AddLeague')));
const EditLeague = Loadable(lazy(() => import('../pages/dashboard/League/EditLeague')));
const Team = Loadable(lazy(() => import('../pages/dashboard/Team/Team')));
const AddTeam = Loadable(lazy(() => import('../pages/dashboard/Team/AddTeam')));
const EditTeam = Loadable(lazy(() => import('../pages/dashboard/Team/EditTeam')));
const LeagueTeam = Loadable(lazy(() => import('../pages/dashboard/LeagueTeam/LeagueTeam')));
const AddLeagueTeam = Loadable(lazy(() => import('../pages/dashboard/LeagueTeam/AddLeagueTeam')));
const EditLeagueTeam = Loadable(lazy(() => import('../pages/dashboard/LeagueTeam/EditLeagueTeam')));
const Matches = Loadable(lazy(() => import('../pages/dashboard/Matches/Matches')));
const AddMatcheResult = Loadable(lazy(() => import('../pages/dashboard/Matches/AddMatchResult')));
const AddMatches = Loadable(lazy(() => import('../pages/dashboard/Matches/AddMatches')));
const EditMatches = Loadable(lazy(() => import('../pages/dashboard/Matches/EditMatches')));
const MatcheTicket = Loadable(lazy(() => import('../pages/dashboard/Matches/MatchTicket/MatcheTicket')));
const AddMatcheTicket = Loadable(lazy(() => import('../pages/dashboard/Matches/MatchTicket/AddMatcheTicket')));
const EditMatcheTicket = Loadable(lazy(() => import('../pages/dashboard/Matches/MatchTicket/EditMatcheTicket')));

const Tickets = Loadable(lazy(() => import('../pages/dashboard/Ticket/Ticket')));
const Order = Loadable(lazy(() => import('../pages/dashboard/Order/Order')));
const BankInfo = Loadable(lazy(() => import('../pages/dashboard/BankInfo/BankInfo')));

const AddTickets = Loadable(lazy(() => import('../pages/dashboard/Ticket/AddTicket')));
const EditTicket = Loadable(lazy(() => import('../pages/dashboard/Ticket/EditTicket')));
const BuyerAndSeller = Loadable(lazy(() => import('../pages/dashboard/BuyerAndSeller/BuyerAndSeller')));

const Category = Loadable(lazy(() => import('../pages/dashboard/Category/Category')));
const AddCategory = Loadable(lazy(() => import('../pages/dashboard/Category/AddCategory')));
const EditCategory = Loadable(lazy(() => import('../pages/dashboard/Category/EditCategory')));
const HomePage = Loadable(lazy(() => import('../pages/Home')));

const TicketDetailPage = Loadable(lazy(() => import('../pages/dashboard/TicketDetailPage')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));
const About = Loadable(lazy(() => import('../pages/About')));
const Buying = Loadable(lazy(() => import('../pages/Buying')));
const Teamss = Loadable(lazy(() => import('../pages/Team')));
const AllMatches = Loadable(lazy(() => import('../pages/AllMatches')));
const Checkout = Loadable(lazy(() => import('../pages/Checkout')));
const EventsLeagues = Loadable(lazy(() => import('../sections/home/EventsLeagues')));
const EventMatch = Loadable(lazy(() => import('../sections/home/EventMatch')));
const AllLeagues = Loadable(lazy(() => import('../sections/home/AllLeague')));
const AllMatch = Loadable(lazy(() => import('../sections/home/AllMatch')));
const AllEvents = Loadable(lazy(() => import('../sections/home/AllEvents')));
const SoldTickets = Loadable(lazy(() => import('../sections/home/SoldTickets')));
const SoldMatch = Loadable(lazy(() => import('../sections/home/SoldMatch')));


