/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable array-callback-return */
/* eslint-disable react/prop-types */
/* eslint-disable react/button-has-type */
import { useLocation, useNavigate } from 'react-router-dom';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import { useEffect, useState } from 'react';
import { Box, AppBar, Toolbar, Container, Typography, TextField, Autocomplete, CircularProgress } from '@mui/material';
// hooks
import useMediaQuery from '@mui/material/useMediaQuery';
import CloseIcon from '@mui/icons-material/Close';
import useOffSetTop from '../../hooks/useOffSetTop';
import useResponsive from '../../hooks/useResponsive';
// utils
import cssStyles from '../../utils/cssStyles';
// config
import { HEADER } from '../../config';
// components
//
import MenuDesktop from './MenuDesktop';
import MenuMobile from './MenuMobile';
import navConfig from './MenuConfig';
import axiosInstance from '../../utils/axios';
import Logo from '../../components/Logo';

// ----------------------------------------------------------------------

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: HEADER.MOBILE_HEIGHT,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('md')]: {
    height: HEADER.MAIN_DESKTOP_HEIGHT,
  },
  background: 'none !important',
}));

const ToolbarShadowStyle = styled('div')(({ theme }) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: 'auto',
  borderRadius: '50%',
  position: 'absolute',
  width: `calc(100% - 48px)`,
  boxShadow: theme.customShadows.z8,
}));

// ----------------------------------------------------------------------

export default function MainHeader({ navConfigToogle }) {
  const isOffset = useOffSetTop(HEADER.MAIN_DESKTOP_HEIGHT);
  const navigate = useNavigate();
  const theme = useTheme();
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [inputVisible, setInputVisible] = useState(false);
  const isMobile = useMediaQuery('(max-width:600px)');
  const { pathname } = useLocation();
  const isDesktop = useResponsive('up', 'md');
  const isHome = pathname === '/';
  const navConfigs = navConfig(navConfigToogle);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axiosInstance.get('/user/match');
        setOptions(response?.data?.matches);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const filteredOptions = options?.filter((option) => option?.name?.toLowerCase()?.includes(inputValue?.toLowerCase()));

  const handleOptionSelect = (event, value) => {
    if (value) {
      setInputValue('');
      navigate(`/allmatches/${value?.id}`, { state: { filteredOptions } });
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && inputValue) {
      setInputValue('');
      navigate(`/allmatches/${inputValue?.id}`, { state: { filteredOptions } });
    }
  };


  const toggleInputVisibility = () => {
    setInputVisible(!inputVisible);
  };

  return (
    <AppBar sx={{ boxShadow: 0, backgroundColor: 'black', top: { xs: 0, sm: '60px' } }}>
      <ToolbarStyle
        disableGutters
        sx={{
          justifyContent: 'space-between',
          ...(isOffset && {
            ...cssStyles(theme).bgBlur(),
            height: { md: HEADER.MAIN_DESKTOP_HEIGHT - 16 },
          }),
        }}
      >
        <Container
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            maxWidth: '100% !important',
            margin: '0px',
            padding: '0px',
          }}
        >
          <Box className="d-flex align-items-center gap-1" style={{ marginLeft: { md: '26px', xs: '0px' } }}>
            <Typography variant="div" className="position-relative">
              <Box sx={{ flexGrow: 1 }} />
              {isDesktop && <MenuDesktop isOffset={isOffset} isHome={isHome} navConfig={navConfigs} />}
              {!isDesktop && <MenuMobile isOffset={isOffset} isHome={isHome} navConfig={navConfigs} />}
            </Typography>
          </Box>

          <Logo sx={{ mx: 2.5, my: 3, display: { xs: 'block', sm: 'none' } }} />



          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {isMobile && !inputVisible ? (
              <SearchIcon sx={{ color: 'white', cursor: 'pointer' }} onClick={toggleInputVisibility} />
            ) : (
              <Autocomplete
                freeSolo
                options={filteredOptions}
                onInputChange={(event, newValue) => setInputValue(newValue)}
                onChange={handleOptionSelect}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    placeholder="Search"
                    onKeyPress={handleKeyPress}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loading ? (
                            <CircularProgress size={20} sx={{ color: 'white' }} />
                          ) : (
                            isMobile && (
                              <CloseIcon
                                sx={{ color: 'white', cursor: 'pointer' }}
                                onClick={toggleInputVisibility}
                              />
                            )
                          )}
                        </>
                      ),
                    }}
                    sx={{
                      color: 'white',
                      width: isMobile ? (inputVisible ? '150px' : '0') : '446px',
                      maxWidth: isMobile ? '150px' : '446px',
                      transition: 'width 0.5s ease',
                      input: {
                        color: 'white',
                      },
                      '.MuiInputBase-root': {
                        backgroundColor: 'transparent',
                        borderBottom: '1px solid #fff',
                      },
                    }}
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props} style={{ padding: '8px', borderBottom: '1px solid #fff' }}>
                    {option.name}
                  </li>
                )}
              />
            )}
          </Box>
        </Container>
      </ToolbarStyle>

      {isOffset && <ToolbarShadowStyle />}
    </AppBar>
  );
}
