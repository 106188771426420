/* eslint-disable no-else-return */
import Iconify from '../../components/Iconify';

const ICON_SIZE = {
  width: 22,
  height: 22,
};

const generateMenuConfig = (isNavConfigToogle) => {
  if (isNavConfigToogle) {
    return [
      {
        title: 'HOME',
        icon: <Iconify icon={'eva:home-fill'} {...ICON_SIZE} />,
        path: '/',
      },
      {
        title: 'ALL MATCHES',
        icon: <Iconify icon={'eva:home-fill'} {...ICON_SIZE} />,
        path: '/allmatches',
      },
      {
        title: 'LEAGUES',
        icon: <Iconify icon={'eva:book-open-fill'} {...ICON_SIZE} />,
        path: '/allleagues',
      },
      {
        title: 'ABOUT',
        icon: <Iconify icon={'eva:book-open-fill'} {...ICON_SIZE} />,
        path: '/about',
      },
      {
        title: 'CONTACT',
        icon: <Iconify icon={'eva:home-fill'} {...ICON_SIZE} />,
        path: '/contact',
      },
    ];
  } else {
    return [
      {
        title: 'TICKETS',
        icon: <Iconify icon={'eva:home-outline'} {...ICON_SIZE} />,
        path: '/tickets',
      },
      {
        title: 'MY WALLET',
        icon: <Iconify icon={'eva:book-outline'} {...ICON_SIZE} />,
        path: '/order',
      },
      {
        title: 'SOLD MATCH',
        icon: <Iconify icon={'eva:book-outline'} {...ICON_SIZE} />,
        path: '/soldmatch',
      },
      {
        title: 'BANK INFO',
        icon: <Iconify icon={'eva:calendar-outline'} {...ICON_SIZE} />,
        path: '/bankinfo',
      },
    ];
  }
};

export default generateMenuConfig;
